const unixToDate = unix => {
    return new Date(unix * 1000);
};
const dateFormat = date => {
    return date.toLocaleString('it-IT', {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};
const localDateFormat = date => {
    return date.toLocaleString('it-IT', {
        timeZone: 'Europe/Rome',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};
const leftPadWithChar = (num, char = '0', charsTotNum = 2) => {
    const loops = charsTotNum - 1;
    let ret = num + ''; //String
    for (let i = loops; i > 0; i--) {
        const ceil = Math.pow(10, i);
        if (num < ceil) {
            ret = char + ret;
        }
    }
    return ret;
};

const sprintfDate = (date, format, locale) => {
    if (typeof date.startsWith === 'function') {
        if (date.startsWith('0000') || date.startsWith('1-01-01')) {
            date = '2200-01-01'; //Fallback far in the future if zero-value
        }
    }
    if (typeof date.getMonth !== 'function') {
        //Try to parse as a date
        date = new Date(date);
    }
    //If is still an invalid date => Error
    if (typeof date.getMonth !== 'function') {
        //Try to parse as a date
        return 'Invalid date';
    }
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const yy = date.getFullYear() + '';
    const y = yy.substr(-2);
    const h = date.getHours();
    const i = date.getMinutes();
    const s = date.getSeconds();

    const dd = leftPadWithChar(d);
    const mm = leftPadWithChar(m);
    const hh = leftPadWithChar(h);
    const ii = leftPadWithChar(i);
    const ss = leftPadWithChar(s);
    format = format.replace(new RegExp('%d', 'g'), dd);
    format = format.replace(new RegExp('%j', 'g'), d);
    format = format.replace(new RegExp('%m', 'g'), mm);
    let formatter = new Intl.DateTimeFormat(locale, { month: 'short' });
    format = format.replace(new RegExp('%M', 'g'), formatter.format(date));
    formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    format = format.replace(new RegExp('%F', 'g'), formatter.format(date));
    format = format.replace(new RegExp('%n', 'g'), m);
    format = format.replace(new RegExp('%Y', 'g'), yy);
    format = format.replace(new RegExp('%y', 'g'), y);
    format = format.replace(new RegExp('%H', 'g'), hh);
    format = format.replace(new RegExp('%i', 'g'), ii);
    format = format.replace(new RegExp('%s', 'g'), ss);
    return format;


};
const makeTranslations = (model, languages) => {
    const translations = {};
    if (!languages) {
        return translations;
    }
    Object.keys(languages).forEach(k => {
        translations[k] = {
            ...model
        };
    });
    return translations;
};

const sanitizeHtml = (t) => {
    if (!t) return "";
    t = t.replaceAll('\r\n', '\n');
    t = t.replaceAll('\r', '\n');
    t = t.replaceAll('\n', ' ');
    t = t.replaceAll('  ', ' ');
    t = t.replace(/ +(?= )/g, '');
    t = t.replaceAll('> <', '><'); //Remove spaces between tags
    return t;
};

const sluggify = text => {
    text = text
        .toString()
        .toLowerCase()
        .trim();

    const sets = [
        { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
        { to: 'c', from: '[ÇĆĈČ]' },
        { to: 'd', from: '[ÐĎĐÞ]' },
        { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
        { to: 'g', from: '[ĜĞĢǴ]' },
        { to: 'h', from: '[ĤḦ]' },
        { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
        { to: 'j', from: '[Ĵ]' },
        { to: 'ij', from: '[Ĳ]' },
        { to: 'k', from: '[Ķ]' },
        { to: 'l', from: '[ĹĻĽŁ]' },
        { to: 'm', from: '[Ḿ]' },
        { to: 'n', from: '[ÑŃŅŇ]' },
        { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
        { to: 'oe', from: '[Œ]' },
        { to: 'p', from: '[ṕ]' },
        { to: 'r', from: '[ŔŖŘ]' },
        { to: 's', from: '[ßŚŜŞŠ]' },
        { to: 't', from: '[ŢŤ]' },
        { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
        { to: 'w', from: '[ẂŴẀẄ]' },
        { to: 'x', from: '[ẍ]' },
        { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
        { to: 'z', from: '[ŹŻŽ]' },
        { to: '-', from: '[·/_,:;\']' },
    ];
    sets.forEach(set => {
        text = text.replace(new RegExp(set.from, 'gi'), set.to);
    });

    return text
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};
const date2API = d => {
    if (typeof d.toISOString === 'function') {
        return d.toISOString();
    }
    return null;
};

const tabsWithTranslations = (basePath, id, languages) => {
    let n = 0;
    const menu = [
        {
            label: 'Base',
            icon: 'pi pi-fw pi-globe',
            idx: n,
            to: basePath + '/' + id,
        },
    ];
    Object.keys(languages).forEach(k => {
        menu.push({
            label: languages[k]['label'],
            idx: ++n,
            code: k,
            to: basePath + '/' + id + '/' + k,
        });
    });
    return menu;
};

const tabsOnlyTranslations = (basePath, id, languages) => {
    let n = 0;
    const menu = [];
    Object.keys(languages).forEach(k => {
        menu.push({
            label: languages[k]['label'],
            idx: n++,
            code: k,
            to: basePath + '/' + id + '/' + k,
        });
    });
    return menu;
};

const api2Date = s => {
    if (!s) return null;
    if (typeof s === 'string' && s.startsWith('000')) {
        return null;
    }
    if (typeof s.toISOString === 'function') {
        return s; //nothing to do, already a date
    }
    if (typeof s === 'string') {
        return new Date(s);
    }
    if (typeof s === 'number') {
        return new Date(s * 1000);
    }
    return null;
};

export default {
    report: (...args) => {
        if (process.env.VUE_APP_DEBUG) {
            const title = args.shift();
            console.log(title);
            if (args.length) {
                console.log(...args);
            }

        }
    },
    download: (data, fileName, dataType) => {
        const blob = new Blob([data], { type: dataType });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
    },
    imageToBase64(url, callback) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function() {
            let reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    },
    time: {
        now: () => {
            return Math.floor(new Date().getTime() / 1000);
        },
        unixToDate,
        dateFormat,
        lastUpdate: num => {
            if (!num) {
                return 'Mai';
            } else {
                return dateFormat(unixToDate(num));
            }
        },
        lastUpdateFromTs: ts => {
            if (!ts || ts.includes('0000')) {
                return 'Mai';
            } else {
                return dateFormat(new Date(ts));
            }
        },
        localeLastUpdateFromTs: ts => {
            if (!ts || ts.includes('0000')) {
                return 'Mai';
            } else {
                return localDateFormat(new Date(ts));
            }
        },
        toEnglish: d => {
            if (d === null) return null;
            return d.toISOString();

        },
        api2Date,
        toLocalTime: d => {
            if (d === null) return null;
        },
        sluggifyIsoDate: (text, isShort, locale) => {
            locale = locale || 'it';
            const monthNameType = isShort ? '%M' : '%F';
            const date = new Date(text);
            return sprintfDate(
                date,
                '%d-' + monthNameType + '-%Y',
                locale,
            ).toLowerCase();
        },
    },
    models: {
        getBookEmbedCode(book) {
            if (book.id) return '{{book-' + book.id + '}}';
            return '';
        },
        getArticleEmbedCode(art) {
            if (art.id) return '{{article-' + art.id + '}}';
            return '';
        },
        apiCommons(model, translation, parent_id = '') {
            return {
                modelCopy(){
                    return { ...model };
                },
                tabs(basePath, id, languages) {
                    return tabsWithTranslations(basePath, id, languages);
                },
                translationsTabs(basePath, id, languages) {
                    return tabsOnlyTranslations(basePath, id, languages);
                },
                title(m) {
                    return 'Modifica record ' + m.slug;
                },
                translationParentId(t, fallback) {
                    const f = fallback || 0;
                    if (t[parent_id]) return t[parent_id];
                    else return f;
                },
                newTranslation(id, language) {
                    const t = JSON.parse(JSON.stringify(translation));
                    t.locale = language;
                    if (parent_id) {
                        t[parent_id] = id;
                    }
                    return t;
                },
                newModel(languages) {
                    const m = { ...model };
                    m.translations = makeTranslations(translation, languages);
                    return m;
                },
                parse(m) {
                    //FROM API
                    return m;
                },
                format(m) {
                    //TO API
                    return m;
                },
                parseTranslation(vIn) {
                    //FROM API
                    const t = { ...vIn };
                    //Sanitize strings
                    const keys = Object.keys(t);
                    if (keys && keys.length) {
                        keys.forEach(k => {
                            if (t[k] && typeof t[k] === 'string') {
                                t[k] = sanitizeHtml(t[k]);
                            }
                        });
                    }
                    if (t['pub_at']) {
                        t['pub_at'] = api2Date(t['pub_at']);
                    }
                    return t;
                },
                formatTranslation(vIn) {
                    //TO API
                    const t = { ...vIn };
                    if (t['pub_at']) {
                        t['pub_at'] = date2API(t['pub_at']);
                    }
                    return t;
                },
            };
        },
        makeTranslations,
        fillTranslation(model, translation) {
            Object.keys(translation).forEach(k => {
                if (typeof model[k] === 'undefined') {
                    model[k] = translation[k];
                }
            });
            return model;
        },
        sanitizeHtml: sanitizeHtml,
        tabs: tabsWithTranslations,
        sluggifyIsoDate: (text, isShort, locale) => {
            locale = locale || 'it';
            const monthNameType = isShort ? '%M' : '%F';
            const date = new Date(text);
            return sprintfDate(
                date,
                '%d-' + monthNameType + '-%Y',
                locale,
            );
        },
        sluggify,
        verifyUrl: value => {
            try {
                new URL(value);
                return true;
            } catch (_) {
                return false;
            }
        },
        validators: {
            START_WITH_SLASH: value => {
                return value.startsWith("/");
            },
            SLUG: value => {
                return /^([a-z0-9_-]{1,100})$/.test(value);
            },
            INTERCOM_ATTRIBUTE: value => {
                if (value === "") return true;
                return /^([a-z0-9_]{1,100})$/.test(value);
            },
            SLUG_OPTIONAL: value => {
                return /^([a-z0-9_-]{0,100})$/.test(value);
            },
            PUBLISH_DATE: function(v) {
                if (v) {
                    //Must be date
                    return (typeof v.toISOString === 'function');
                }
                //there's no date
                return this.model.publish_state !== 'published';
            },
            EMAIL: (v) => {
                const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                return v.match(validRegex);
            }

        },
        optionLabel(rows, id){
            if (!rows) return "-";
            if (!rows.length) return "-";
            const f = rows.filter(row => row.id === id);
            if (f.length > 0) return f[0].label;
            return "-";
        },
        listsToDropdown(rows, lang = 'it', field = 'name') {
            const ret = [];
            if (rows && rows.length) {
                rows.forEach(row => {
                    const obj = {
                        id: row.id,
                        label: row.slug,
                    };
                    if (field && (row[field])) {
                        obj.label = row[field];
                    }
                    if (row.translations && row.translations[lang] && row.translations[lang][field]) {
                        obj.label = row.translations[lang][field];
                    }
                    ret.push(obj);
                });
            }
            return ret;
        },
        uploadFileTypes: {
            IMAGE: 'IMAGE',
            PDF: 'PDF',
            YAML: 'YAML',
            SOUND: 'SOUND',
            CSV: 'CSV',
            UNKNOWN: 'UNKNOWN',
        },
        unwrapP(model, fieldName){
            const originalString = model[fieldName];
            let stripped = originalString.replace(/^<p[^>]*>/i, "");
            stripped = stripped.replace(/<\/p>$/i, "");
            model[fieldName] = stripped;
            return model;
        }
    },
};
