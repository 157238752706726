import { createApp, h } from 'vue';
import router from './router';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dock from 'primevue/dock';
import Dropdown from './components/4books/dropdown/Dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
//import FullCalendar from 'primevue/fullcalendar';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import "primeflex/primeflex.css";

import CodeHighlight from './AppCodeHighlight';

import Quill from 'quill';
import htmlEditButton from "quill-html-edit-button";
Quill.register("modules/htmlEditButton", htmlEditButton);

//4books
import SaveButton from './components/4books/forms/SaveButton';
import Flag from './components/Flag';

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';

import store from './store/store';

//FONTAWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret, faGift, faAward, faPodcast, faPenNib, faFeather, faGlobe, faKey, faFingerprint, faVials, faCut
    , faExternalLinkAlt, faFileImport, faBookMedical, faChartLine, faFilter, faUserAltSlash, faIndustry, faDirections,
    faTrafficLight, faEdit, faUserTag, faBezierCurve, faUsers, faTrash, faRoute, faUserFriends, faSitemap, faThumbtack,
    faSolarPanel, faChartBar, faSignInAlt, faDatabase, faShoppingCart, faQuestion, faArchive, faBrain, faUserInjured,
    faVoicemail, faListAlt, faHeadset, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf, faNewspaper, faClock, faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { faIntercom, faTeamspeak, faChrome, faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUserSecret, faIntercom, faGift, faAward, faPodcast,
    faFilePdf, faPenNib, faNewspaper, faClock, faTeamspeak, faFeather, faGlobe, faKey, faFingerprint, faVials, faCut,
    faExternalLinkAlt, faFileImport, faBookMedical, faChartLine, faFilter, faUserAltSlash, faIndustry, faDirections,
    faTrafficLight, faEdit, faUserTag, faBezierCurve, faUsers, faTrash, faRoute, faUserFriends, faSitemap, faThumbtack,
    faSolarPanel, faChartBar, faSignInAlt, faDatabase, faChrome, faAndroid, faApple, faShoppingCart, faQuestion,
    faArchive, faBrain, faUserInjured, faVoicemail, faListAlt, faCommentAlt, faHeadset, faDollarSign)

const app = createApp({
    computed: {
        ViewComponent() {
            return App;
        },
    },
    render() {
        return h(this.ViewComponent);
    },
});
app.config.performance = true;
app.config.optionMergeStrategies.beforeRouteUpdate = app.config.optionMergeStrategies.beforeRouterEnter;
//app.config.devtools = true;
//window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app.constructor;

app.use(store);
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('BlockUI', BlockUI);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Dock', Dock);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
//app.component('FullCalendar', FullCalendar);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);

app.component('font-awesome-icon', FontAwesomeIcon)


app.component('SaveButton', SaveButton);
app.component('Flag', Flag);

const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    [{ 'header': [3, 4, 5, 6, false] }],
    [{ 'align': [] }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link', 'image'],
    ['clean'],
    ['blockquote', 'code-block']
];

const quOptions = {
    placeholder: '',
    theme: 'snow',
    modules: {
        toolbar: {
            container: toolbarOptions,
            handlers: {
                image: function(){
                    const range = this.quill.getSelection();
                    const value = prompt('please copy paste the image url here.');
                    if(value){
                        this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
                    }
                }
            }
        },
        htmlEditButton: {},
    }
};
// set default globalOptions prop
QuillEditor.props.globalOptions.default = () => quOptions;
app.component('HtmlEditor', QuillEditor);

app.mount('#app');
